<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		  	<span class="text-subtitle-1">Proyectos</span>
	  		    <v-spacer></v-spacer>

	  		    <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( proyectos  , 'Proyectos')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

	  		    <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

	  		  </v-card-title>

	  		  <v-card-text>
	  		  	<v-row justify="end">
	  		  		<v-col cols="12" md="6">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="proyectos"
							    class="elevation-0"
							    :search="search"
							    :page.sync="page"
			            @page-count="pageCount = $event"
							    :mobile-breakpoint="100"
			            hide-default-footer
			            dense
							  >
 
							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.estatus="{ item }">
							      <v-chip 
							      	v-if="item.estatus == 1"
							      	small
							      	color="green"
							      	dark
							      >Activo</v-chip>

							      <v-chip 
							      	v-else
							      	small
							      	color="error"
							      	dark
							      >Inactivo</v-chip>
							    </template>

							    <template v-slot:item.escuela="{ item }">
							      <v-chip small color="colorinbi" dark v-if="item.escuela == 1">INBI</v-chip>
							      <v-chip small color="colorfast" dark v-if="item.escuela == 2">FAST</v-chip>
							    </template>

							    <template v-slot:item.actions="{ item }">
							      <v-icon
							        small
							        class="mr-2"
							        color="primary"
							        @click="editItem(item)"
							      >
							        mdi-pencil
							      </v-icon>
							      <v-icon
							        small
							        @click="deleteItem(item)"
							        color="error"
							      >
							        mdi-delete
							      </v-icon>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>
							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		  <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              circle
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-card-actions>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.proyecto"
                  label="Proyecto"
                  outlined
                  hide-details
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
              	<v-switch 
              		label="Activo"
                  hide-details
              		v-model="editedItem.estatus"
                  >
              	</v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            class="elevation-6"
            dark
            @click="save"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row>
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-h6" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el proyecto?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text large class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C" dark class="elevation-6" large @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="6">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

	</v-container>
</template>
<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

	import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  export default {

    mixins: [ validarErrores, funcionesExcel ],

    components:{
      Alerta,
      carga,
    },

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'ID'       , align: 'start', value: 'id_proyecto' },
        { text: 'Proyecto' , value: 'proyecto'   },
        { text: 'Estatus'  , value: 'estatus' },
        { text: 'Actions'  , value: 'actions', sortable: false },
      ],
      proyectos: [],
      editedIndex: -1,
      editedItem: {
        id_proyecto: '',
        proyecto: '',
        estatus: 0,
      },
      defaultItem: {
        id_proyecto: '',
        proyecto: '',
        estatus: 0,
      },

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar Proyecto' : 'Editar Proyecto'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

      'editedItem.estatus': function(newVal) {
      this.editedItem.estatus = newVal ? 1 : 0;
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.proyectos = []
        this.cargar = true
        this.$http.get('consultar.proyectos').then(response=>{
        	this.proyectos = response.data
            
        	this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.proyectos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.proyectos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        // Lo mandapos por el EP
        this.editedItem.deleted = 1
        this.$http.put('update.proyectos/' + this.editedItem.id_proyecto, this.editedItem).then(response=>{
        	this.validarSuccess('Datos eliminados correctamente')
        	this.cargar        = false
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
      	if(this.editedItem.proyecto == ''){
      		return this.validarErrorDirecto('El campo de proyecto esta vacío')
      	}

        if (this.editedIndex > -1) {
          // Activamos el loader
	        this.cargar        = true
	        // Lo mandapos por el EP
            this.$http.put('update.proyectos/' + this.editedItem.id_proyecto, this.editedItem).then(response=>{
          	this.validarSuccess('Datos grabados correctamente')
          	this.initialize()
	        	this.cargar        = false
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })

        } else {
        	// Activamos el loader
	        this.cargar        = true
	        // Lo mandapos por el EP
          this.$http.post('add.proyectos', this.editedItem).then(response=>{
          	this.validarSuccess('Datos grabados correctamente')
          	this.initialize()
	        	this.cargar        = false
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })
        }
        this.close()
      },
    },
  }
</script>
